import React, { useRef } from "react";
import './ContactUs.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import contactus from './images/contactus_img.svg';
import mail from './icons/mail_icon.svg';
import phone from './icons/phone_icon.svg';
import mailMobile from './icons/mail_icon_mobile.svg';
import phoneMobile from './icons/phone_icon_mobile.svg';
import { forwardRef } from "react";
import validator from "validator";

const ContactUs = forwardRef((props, ref) => {
    const [formStatus, setFormStatus] = React.useState('Send')
    const form = useRef();
    const Submit = (e) => {
        const formEle = document.querySelector("form");
        const formDatab = new FormData(formEle);
        const number = formDatab.get("Contact_No")
        const email = formDatab.get("Email_ID")
        const regex = new RegExp("([0-9]){10,}$")
        if(regex.test(number) == true && validator.isEmail(email) == true){
            e.preventDefault()
            fetch(
              "https://script.google.com/macros/s/AKfycbzxCA61a2ErNf33FM6-TgybzzzrHW5Mn7kE5SMEAUTC5vIIGOdyioeLkHAl1WAbOrMi/exec",
              {
                method: "POST",
                body: formDatab
              }
            )
              .then((data) => {
                  if(data?.ok){
                    document.querySelector("form").reset();
                    console.log("form data", formDatab.set("Name", " "))
                      alert("Thanks for reaching out! We will be in touch soon.")
                  }
                  else{
                    alert("Sorry, there was an error submitting your form. Please try again")
                  }
    
              })
              .catch((error) => {
                console.log(error);
              });
        }
        else{
            if(regex.test(number) == false){
                e.preventDefault()
                alert("Please enter a valid 10 digit contact number.")
            }
            else{
                e.preventDefault()
                alert("Please enter a valid email address.")
            }
        }
       
      }
      const SubmitMobile = (e) => {
        const formElement = document.getElementById("formMobile");
        const formDataMobile = new FormData(formElement);
        const number = formDataMobile.get("Contact_No")
        const email = formDataMobile.get("Email_ID")
        const regex = new RegExp("([0-9]){10,}$")
        if(regex.test(number) == true && validator.isEmail(email) == true){
            e.preventDefault()
            fetch(
              "https://script.google.com/macros/s/AKfycbzxCA61a2ErNf33FM6-TgybzzzrHW5Mn7kE5SMEAUTC5vIIGOdyioeLkHAl1WAbOrMi/exec",
              {
                method: "POST",
                body: formDataMobile
              }
            )
              .then((data) => {
                if(data?.ok){
                    document.getElementById("formMobile").reset()
                    alert("Thanks for reaching out! We will be in touch soon.")
                }
                else{
                  alert("Sorry, there was an error submitting your form. Please try again")
                }
              })
              .catch((error) => {
                console.log(error);
              });
        }
        else{
            if(regex.test(number) == false){
                e.preventDefault()
                alert("Please enter a valid 10 digit contact number.")
            }
            else{
                e.preventDefault()
                alert("Please enter a valid email address.")
            }
        }
      }

    return (
        <>
        <div ref={ref}>
            <div className="d-none d-lg-block" >
                <div className="contact-form" >
                    <Container className="text-center">
                        <Row>
                           
                                <div className="form-heading font-face-title d-block">Feel Free To Contact Us</div>
                            
                        </Row>
                    </Container>
                    <Container className="form-controls mt-5 ">
                        <Row className="mb-5">
                            {/* <Col md={1} xs={1}></Col> */}
                            <Col md={5} xs={5} sm={5} lg={5} className="mt-5">

                                <img src={contactus} alt={'contact us'} className='mb-4 w-60' />
                                <div className="d-flex align-items-center contact-info"><img src={phone} alt={'phone'} className="contact-icon" /><div className="font-face-forms">+91-9619300426</div></div>
                                <div className="d-flex align-items-center contact-info mb-5"><img src={mail} alt={'mail'} className="contact-icon" /><div className="font-face-forms">info@filamentai.in</div></div>

                            </Col>

                            <Col md={6} xs={6} sm={6} lg={6} >
                                {/* <Row>
                                    <Col md={12} xs={12} sm={12} lg={12} >
                                        <div className="form-items mb-4 mt-5">
                                            <div className="font-face-heading ">Connect With Us</div>
                                        </div>
                                    </Col>
                                </Row> */}
                                <form className="form" onSubmit={(e) => Submit(e)} >
                                <Row className="mt-5">
                                    <Col md={6} xs={6} sm={6} lg={6} >
                                        <div className="form-items mb-3">
                                            <div className="mb-2 font-face-forms">Name</div>
                                            <input type="text" className="font-face-placeholder input form-elements" placeholder="Enter your name" name="Name" required/>
                                        </div>
                                    </Col>
                                    <Col md={6} xs={6} sm={6} lg={6} >
                                        <div className="form-items mb-3">
                                            <div className="mb-2 font-face-forms">Company</div>
                                            <input type="text" className="font-face-placeholder input form-elements" placeholder="Enter your company name" name="Company" required/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={6} sm={6} lg={6} >
                                        <div className="form-items mb-3">
                                            <div className="mb-2 font-face-forms">Email</div>
                                            <input type="text" className="font-face-placeholder input form-elements" placeholder="Enter your email address" name="Email_ID" required />
                                        </div>
                                    </Col>
                                    <Col md={6} xs={6} sm={6} lg={6}>
                                        <div className="form-items mb-3">
                                            <div className="mb-2 font-face-forms">Phone number</div>
                                            <input type="text" className="font-face-placeholder input form-elements" placeholder="Enter your phone number"  name="Contact_No" required />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12} sm={12} lg={12}>
                                        <div className="form-items mb-4">
                                            <div className="mb-2 font-face-forms">Enter your message</div>
                                            <textarea type="textarea" className="font-face-placeholder input form-elements" placeholder="Message"  rows="7" cols="50" name="Message"/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12} sm={12} lg={12}>
                                        <div className="form-items mb-5">
                                            <input name="Name" type="submit" className="submit-button w-100 font-face-button"></input>
                                        </div>
                                    </Col>
                                </Row>
                                </form>
                            </Col>
                            <Col md={1} xs={1} sm={1} lg={1}></Col>
                        </Row>
                    </Container>
                </div>

                <div>

                </div>
            </div>
            <div className="d-lg-none contact-form-mobile-container pt-5 pb-5">
                <Container>
                    <Row className="form-heading font-face-title-mobile">
                        <div className="mb-3" >Feel Free To Contact Us</div>
                        <Col md={4} sm={4} xs={4} lg={4}></Col>
                        <Col md={4} sm={4} xs={4} lg={4}>
                            <img src={contactus} alt={'contact us'} className='mb-3 w-100' />
                        </Col>
                        <Col md={4} sm={4} xs={4} lg={4}></Col>
                    </Row>
                    <Row>
                        <Col md={2} sm={2} xs={2} lg={2}></Col>
                        <Col md={8} sm={8} xs={8} lg={8}>
                            <div className="d-flex align-items-center contact-info text-center"><img src={phoneMobile} alt={'phone'} className="contact-icon" /><div className="font-face-forms-mobile form-heading">+91-9619300426</div></div>
                            <div className="d-flex align-items-center contact-info mb-5"><img src={mailMobile} alt={'mail'} className="contact-icon" /><div className="font-face-forms-mobile form-heading">info@filamentai.in</div></div>
                        </Col>
                        <Col md={2} sm={2} xs={2} lg={2}></Col>
                    </Row>
                    <Row>
                        <Col md={1} sm={1} xs={1} lg={1}></Col>
                        <Col md={10} sm={10} xs={10} lg={10}>
                       
                            <div className="contact-us-form-mobile p-4">
                            
                                <Row>
                                    {/* <Col md={1} sm={1} xs={1} lg={1}></Col> */}
                                    
                                    <Col md={12} sm={12} xs={12} lg={12}>
                                    <form id="formMobile" onSubmit={(e) => SubmitMobile(e)} >
                                        <div className="form-elements font-face-forms-mobile mb-2">
                                            Name
                                        </div>
                                        <input type="text" className="font-face-placeholder-mobile input mb-3 form-elements" placeholder="Enter your name" name="Name" required/>
                                        <div className="form-elements font-face-forms-mobile mb-2">
                                            Company
                                        </div>
                                        <input type="text" className="font-face-placeholder-mobile input mb-3 form-elements" placeholder="Enter your company" name="Company" required/>
                                        <div className="form-elements font-face-forms-mobile mb-2">
                                            Email
                                        </div>
                                        <input type="test" className="font-face-placeholder-mobile input mb-3 form-elements" placeholder="Enter your email" name="Email_ID" required/>
                                        <div className="form-elements font-face-forms-mobile mb-2">
                                            Phone Number
                                        </div>
                                        <input type="text" className="font-face-placeholder-mobile input mb-3 form-elements" placeholder="Enter your phone number" name="Contact_No" required />
                                        <div className="form-elements font-face-forms-mobile mb-2">
                                            Message
                                        </div>
                                        <input type="text" className="font-face-placeholder-mobile input mb-3 form-elements" placeholder="Enter your message" name="Message"/>
                                        <div className="form-items mt-5 mb-4">
                                            <input name="Name" type="submit" className="submit-button w-100 font-face-button-mobile"></input>
                                        </div>
                                        </form>
                                    </Col>
                                    {/* <Col md={1} sm={1} xs={1} lg={1}></Col> */}
                                </Row>
                            </div>

                        </Col>
                        <Col md={2} sm={1} xs={1} lg={1}></Col>
                    </Row>
                </Container>
            </div>
        </div>
        </>
    )
});

export default ContactUs;